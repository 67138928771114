<template>
  <v-container fluid class="pt-0 fill-height align-start">
    <v-row justify="center" class="fill-height" style="align-content: start">
      <v-col cols="12" md="12" class="pb-2" style="height: 64px">
        <v-toolbar elevation="2" class="pa-0" height="52">
          <v-toolbar-title class="py-2">
            Створення нової послуги
          </v-toolbar-title>
          <v-spacer/>
          <YouTubeButton
              button-class="grey lighten-4"
              icon-color="error lighten-1"
              :icon-size="26"
              button-style="height: 42px; width: 42px; margin-right: 3px;"
              href="https://youtu.be/KtKz5C3tFNQ"
          />
        </v-toolbar>
      </v-col>
      <v-col cols="6" md="4" :style="$vuetify.breakpoint.mdAndUp ? 'height: 92vh;' : ''">
        <v-card class="fill-height">
          <v-list-item-group
              style="
                      height: 100%;
                      overflow-y: auto;
                      background-color: #ffffff;
                      border-left: 3px solid #fafafa;
                      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
                    "
          >
            <v-list class="py-0">
              <v-list-item
                  link
                  v-for="(item, idx) in serviceList" :key="`temp-serv-${idx}`"
                  @click.stop="fillService(item)"
                  style="border-bottom: 1px solid #f5f5f5"
              >
                <v-list-item-icon>
                  <v-icon size="32" :color="getAccountIcon(item.icon, 'color')">{{
                      getAccountIcon(item.icon, 'icon')
                    }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getCalculateMethod(item.calculate_method) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-group>
        </v-card>
      </v-col>
      <v-col cols="6" md="8" :style="$vuetify.breakpoint.mdAndUp ? 'height: 92vh;' : ''">
        <v-card class="fill-height">
          <v-form v-model="formValid" class="fill-height" ref="form">
            <v-row class="ma-0 py-3 fill-height" style="align-content: flex-start; overflow: auto">
              <v-col cols="12">
                <v-text-field type="text" hide-details filled label="Назва повна"
                              v-model="service.name"
                              required :rules="[v => !!v || 'Це поле є обов’язковим']"
                              :class="service.name ? '' : 'req-star'"
                              color="grey"
                />
              </v-col>
              <v-col cols="12" class="d-flex">
                <v-text-field type="text" hide-details filled label="Назва коротка"
                              v-model="service.short_name"
                              required :rules="[v => !!v || 'Це поле є обов’язковим']"
                              :class="service.short_name ? '' : 'req-star'"
                              style="flex: 1; margin-right: 18px"
                              color="grey"
                />
                <v-text-field type="text" hide-details filled label="Абревіатура"
                              v-model="service.abbreviation"
                              required :rules="[v => !!v || 'Це поле є обов’язковим']"
                              :class="service.abbreviation ? '' : 'req-star'"
                              style="flex: 0 0 156px"
                              color="grey"
                />
              </v-col>
              <v-col cols="12" class="d-flex">
                <v-select :items="calculateMethodsSelect" filled label="Спосіб розрахунку"
                          hide-details
                          v-model="service.calculate_method"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="service.calculate_method ? '' : 'req-star'"
                          style="flex: 1; margin-right: 18px"
                          color="grey"
                />
                <v-select :items="accountIconSelect" filled
                          hide-details
                          v-model="service.icon"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="service.icon ? '' : 'req-star'"
                          style="flex: 0 0 156px"
                          return-object
                          @change="changeIcon"
                          color="grey"
                >
                  <template v-slot:selection="{ item }">
                    <v-icon size="40" :color="item.value.color">
                      {{ item.value.icon }}
                    </v-icon>
                  </template>
                  <template v-slot:item="{ item }">
                    <template>
                      <v-list-item-icon>
                        <v-icon size="36" class="mr-3" :color="item.value.color">
                          {{ item.value.icon }}
                        </v-icon>
                      </v-list-item-icon>
                    </template>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="12" v-if="service.calculate_method === 'by_square'">
                <v-select :items="indicators_select" hide-details filled label="Вид показника"
                          v-model="service.indicator_id"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="service.indicator_id ? '' : 'req-star'"
                          color="grey"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-select :items="monetaryServiceSelect" hide-details filled label="Послуга для монетизації"
                          v-model="service.monetary_service"
                          color="grey"
                />
              </v-col>
              <v-col cols="12">
                <v-switch
                    v-model="service.has_counter"
                    inset
                    hide-details
                    class="mt-1" color="success"
                    :label="service.has_counter ? 'Облік по лічильниках' : 'Лічильники не передбачені'"
                ></v-switch>
              </v-col>
              <v-col cols="12" v-if="service.has_counter">
                <v-select :items="services"
                          filled
                          label="Послуга для показів залежної послуги (водопост. для водовідведення)"
                          hide-details
                          v-model="service.service_counter_id"
                          color="grey"
                />
              </v-col>
              <v-col cols="12">
                <v-switch
                    v-model="service.calc_by_norm"
                    inset
                    hide-details
                    class="mt-1" color="success"
                    :label="service.calc_by_norm ? 'Розрахунок по нормах включений' : 'Розрахунок по нормах відключений'"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-switch
                    v-model="service.heating"
                    inset
                    hide-details
                    class="mt-1" color="success"
                    :label="service.heating ? 'Це опалення' : 'Це не є опаленням'"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-btn depressed block color="grey lighten-4" class="mt-7" @click.stop="createService">
                  <v-icon left>
                    mdi-save
                  </v-icon>
                  Зберегти
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {calculateMethodsSelect, getAccountIcon, accountIcons, monetaryServiceSelect, generateIconSelect} from "@/utils/icons";
import {mapGetters, mapActions} from "vuex";
import {FETCH_FLAT_INDICATOR_TYPES} from "@/store/actions/flat_indicators";
import {CREATE_SERVICE, SELECT_SERVICES_WITHOUT_ALL_SERVICE} from "@/store/actions/services";
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "ServiceCreate",
  components: {
    YouTubeButton: () => import("@/components/YouTubeButton")
  },
  computed: {
    ...mapGetters({
      indicators_select: 'getFlatIndicatorTypes',
      services: 'getServicesSelectWithoutAll'
    }),
    accountIconSelect() {
      return this.generateIconSelect()
    }
  },
  data() {
    return {
      formValid: false,
      calculateMethodsSelect,
      accountIcons,
      monetaryServiceSelect,
      serviceList: [
        {
          icon: 'house_pay',
          service: 'house_pay',
          name: 'Утримання будинку',
          short_name: 'Утримання будинку',
          abbreviation: 'УТБ',
          calc_by_norm: false,
          has_counter: false,
          calculate_method: 'by_square',
          indicator_id: null,
          heating: false,
          monetary_service: null
        },
        {
          icon: 'contribution',
          service: 'house_pay',
          name: 'Членські внески',
          abbreviation: 'ЧВ',
          short_name: 'Членські внески',
          calc_by_norm: false,
          has_counter: false,
          calculate_method: 'by_square',
          indicator_id: null,
          heating: false,
          monetary_service: null
        },
        {
          icon: 'garage',
          service: 'house_pay',
          name: 'Плата за гараж',
          abbreviation: 'ГАР',
          short_name: 'Плата за гараж',
          calc_by_norm: false,
          has_counter: false,
          calculate_method: 'by_square',
          indicator_id: null,
          heating: false,
          monetary_service: null
        },
        {
          icon: 'water_cold',
          service: 'cold_water',
          abbreviation: 'ХВП',
          name: 'Постачання холодної води',
          short_name: 'Постачання холодної води',
          calc_by_norm: true,
          has_counter: true,
          calculate_method: 'by_counter',
          monetary_service: null
        },
        {
          icon: 'water_hot',
          service: 'hot_water',
          abbreviation: 'ГВП',
          name: 'Постачання гарячої води',
          short_name: 'Постачання гарячої води',
          calc_by_norm: true,
          has_counter: true,
          calculate_method: 'by_counter',
          heating: false,
          monetary_service: null
        },
        {
          icon: 'sewerage',
          service: 'sewerage',
          abbreviation: 'ВВ',
          name: 'Каналізація (водовідведення)',
          short_name: 'Каналізація (водовідведення)',
          calc_by_norm: true,
          has_counter: true,
          calculate_method: 'by_counter',
          heating: false,
          monetary_service: null
        },
        {
          icon: 'trash',
          service: 'household_waste',
          name: 'Вивіз ТПВ',
          abbreviation: 'ТПВ',
          short_name: 'Вивіз ТПВ',
          calc_by_norm: false,
          has_counter: false,
          calculate_method: 'by_normative',
          heating: false,
          monetary_service: null
        },
        {
          icon: 'liquid_sewage',
          service: 'liquid_sewage',
          name: 'Вивіз РН',
          abbreviation: 'РН',
          short_name: 'Вивіз РН',
          calc_by_norm: false,
          has_counter: false,
          calculate_method: 'by_normative',
          heating: false,
          monetary_service: null
        },
        {
          icon: 'gas',
          service: 'gas',
          name: 'Газ',
          abbreviation: 'ГЗ',
          short_name: 'Газ',
          calc_by_norm: true,
          has_counter: true,
          calculate_method: 'by_counter',
          heating: false,
          monetary_service: null
        },
        {
          icon: 'electro',
          service: 'electricity',
          name: 'Електроенергія',
          short_name: 'Електроенергія',
          abbreviation: 'ЕЛ',
          calc_by_norm: false,
          has_counter: true,
          calculate_method: 'by_counter',
          heating: false,
          monetary_service: null
        },
        {
          icon: 'heating',
          service: 'heat_supply',
          name: 'Тепло',
          abbreviation: 'ТЕП',
          short_name: 'Тепло',
          calc_by_norm: false,
          has_counter: false,
          calculate_method: 'by_square',
          indicator_id: null,
          heating: true,
          monetary_service: null
        },
      ],
      service: {
        name: '',
        short_name: '',
        abbreviation: '',
        main_service: '',
        calculate_method: '',
        icon: '',
        calc_by_norm: false,
        has_counter: false,
        service_counter_id: null,
        indicator_id: null,
        service_icon: '',
        heating: false,
        monetary_service: null
      }
    }
  },
  methods: {
    ...mapActions({
      fetchIndicators: FETCH_FLAT_INDICATOR_TYPES,
      fetchServicesSelect: SELECT_SERVICES_WITHOUT_ALL_SERVICE
    }),
    generateIconSelect,
    getAccountIcon,
    getCalculateMethod(payload) {
      return (this.calculateMethodsSelect.find(item => item.value === payload) || {}).text
    },
    fillService(item) {
      if (!item) {
        return undefined
      }

      const icon_obj = this.accountIcons[item.icon]

      this.service.calculate_method = item.calculate_method
      this.service.has_counter = item.has_counter
      this.service.name = item.name
      this.service.calc_by_norm = item.calc_by_norm
      this.service.icon = icon_obj ? icon_obj : {}
      this.service.main_service = item.service
      this.service.short_name = item.short_name
      this.service.indicator_id = item.indicator_id
      this.service.service_icon = item.icon
      this.service.heating = item.heating
      this.service.abbreviation = item.abbreviation
      this.service.monetary_service = item.monetary_service
    },
    changeIcon(payload) {
      const keys = Object.entries(this.accountIcons)
      const item = keys.find(item => {
        return (item[1].icon === payload?.value?.icon && item[1].color === payload?.value?.color)
      })
      this.service.service_icon = item.length ? item[0] : ''
    },
    createService() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Усі поля повинні бути заповнені',
          color: 'error lighten-1'
        })
        return undefined
      }

      const payload = Object.assign(
          {},
          this.service,
          {icon: this.service.service_icon},
          {calculate_type: this.service.calculate_method}
          )
      delete payload['service_icon']
      delete payload['calculate_method']

      this.$store.dispatch(CREATE_SERVICE, payload)
          .then(el => {
            if (el) {
              this.$router.push({ name: 'Services' })
            }
          })
    }
  },
  async created() {
    await this.fetchIndicators()
    await this.fetchServicesSelect()
  },
  watch: {}
}
</script>

<style scoped>

</style>